import { memo } from "react";
import Swap from "./components/swap/Swap";
import Staking from "./components/staking/Staking";
import Dashboard from "./components/dashboard/Dashboard";
import SwapOptimism from "./components/swap-optimism/SwapOptimism";
import SwapArbitrum from "./components/swap-arbitrum/SwapArbitrum";

import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import NotificationContainer from "./components/common/notification/NotificationContainer";
import ErrorBoundary from "./components/common/error-boundary/ErrorBoundary";
import { AppScope } from "./types";

import "./App.scss";

const APP_SCOPE = (process.env.REACT_APP_SCOPE || "swap") as AppScope;
function renderMainComponentForAppScope() {
  switch (APP_SCOPE) {
    case "dashboard":
      return <Dashboard />;
    case "staking":
      return <Staking />;
    case "swap-optimism":
      return <SwapOptimism />;
    case "swap-arbitrum":
      return <SwapArbitrum />;
    default:
      return <Swap />;
  }
}

function App() {
  return (
    <ErrorBoundary>
      <div className="App">
        <div className="App-layout">
          <Header />
          {renderMainComponentForAppScope()}
          <Footer />
          <NotificationContainer />
        </div>
      </div>
      <div className="App-overlay">
        <div id="modal-portal-container" />
      </div>
    </ErrorBoundary>
  );
}

export default memo(App, () => true);
