import clsx from "clsx";
import { AppScope } from "../../../types";
import "./Navigation.scss";

const APP_SCOPE = (process.env.REACT_APP_SCOPE || "swap") as AppScope;

const NAVIGATION_ITEMS = [
  {
    scopes: ["swap", "swap-arbitrum", "swap-optimism"] as AppScope[],
    label: "Mint",
    href:
      (
        {
          swap: "https://app.uxd.fi",
          "swap-arbitrum": "https://arbitrum.uxd.fi",
          "swap-optimism": "https://optimism.uxd.fi",
        } as Record<AppScope, string>
      )[APP_SCOPE] ?? "https://app.uxd.fi",
  },
  {
    scopes: ["staking"] as AppScope[],
    label: "Stake",
    href: "https://stake.uxd.fi",
  },
  {
    scopes: ["dashboard"] as AppScope[],
    label: "Dashboard",
    href: "https://dashboard.uxd.fi",
  },
] as const;

const NavigationLink = ({
  children,
  href,
  active,
}: {
  children: string;
  href: string;
  active: boolean;
}) => {
  const Component = active ? "span" : "a";

  const props = active
    ? {
        className: clsx("Navigation-link", "Navigation-link--active"),
        tabIndex: 0,
      }
    : {
        href,
        className: "Navigation-link",
      };

  return <Component {...props}>{children}</Component>;
};

const Navigation = ({ className }: { className: string }) => {
  return (
    <nav className={clsx("Navigation", className)}>
      {NAVIGATION_ITEMS.map(({ scopes, label, href }) => (
        <NavigationLink
          key={label}
          href={href}
          active={scopes.includes(APP_SCOPE)}
        >
          {label}
        </NavigationLink>
      ))}
    </nav>
  );
};

export default Navigation;
