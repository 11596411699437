import { ethers } from "ethers";
import { useEffect, useMemo, useState } from "react";
import {
  ARBITRUM_GOVERNANCE_USDC_TREASURY,
  ETHEREUM_GOVERNANCE_USDC_TREASURY,
} from "../components/dashboard/insurance-fund-positions/InsuranceFundPositions";
import { PublicKey } from "@solana/web3.js";
import { DASHBOARD_API_ENDPOINT } from "../init/constants";
import { AccountLayout, u64 } from "@solana/spl-token";
import { nativeToUi } from "../utils/amount";
import { PythSolanaReceiver } from "@pythnetwork/pyth-solana-receiver";

/** FUND POSITIONS VALUES ON 2024/05/06 */
const MAPLE_FINANCE_USDC_SOLANA = 3_173_364.437212;
const ORCA_AMOUNT = 1_319_229;

const UXP_GOVERNANCE_HOT_WALLET =
  "7M6TSEkRiXiYmpRCcCDSdJGTGxAPem2HBqjW4gLQ2KoE";

const getEthereumUSDCDeposit = async () => {
  const abi = [
    {
      constant: true,
      inputs: [{ name: "_owner", type: "address" }],
      name: "balanceOf",
      outputs: [{ name: "balance", type: "uint256" }],
      type: "function",
    },
  ];

  const contract = new ethers.Contract(
    // USDC
    // https://etherscan.io/address/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48
    "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
    abi
  );
  const provider = new ethers.providers.InfuraProvider(
    "homestead",
    "1b07c06cf0494e879d4eaa6f555cbd7f"
  );
  const balance = await contract
    .connect(provider)
    .balanceOf(ETHEREUM_GOVERNANCE_USDC_TREASURY);

  return Number(ethers.utils.formatUnits(balance ?? 0, 6));
};

const getArbitrumUSDCDeposit = async () => {
  const abi = [
    {
      constant: true,
      inputs: [{ name: "_owner", type: "address" }],
      name: "balanceOf",
      outputs: [{ name: "balance", type: "uint256" }],
      type: "function",
    },
  ];

  const contract = new ethers.Contract(
    // Bridged USDC
    // https://arbiscan.io/token/0xff970a61a04b1ca14834a43f5de4533ebddb5cc8
    "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8",
    abi
  );
  const provider = new ethers.providers.InfuraProvider(
    "arbitrum",
    "1b07c06cf0494e879d4eaa6f555cbd7f"
  );
  const balance = await contract
    .connect(provider)
    .balanceOf(ARBITRUM_GOVERNANCE_USDC_TREASURY);

  return Number(ethers.utils.formatUnits(balance ?? 0, 6));
};

const getMultipleTokenAccountBalances = async (
  accounts: { pubkey: PublicKey; decimals: number }[]
): Promise<(number | null)[]> => {
  const accountsInfo =
    await window.__UXD__.solana.connection.getMultipleAccountsInfo(
      accounts.map(({ pubkey }) => pubkey)
    );

  return accountsInfo.map((accountInfo, i) => {
    if (!accountInfo) return null;

    const nativeAmount = u64.fromBuffer(
      AccountLayout.decode(accountInfo.data).amount
    );

    return nativeToUi(nativeAmount, accounts[i].decimals);
  });
};

export default function useInsuranceFundPositions() {
  const USDCMintAddress = useMemo(
    () => window.__UXD__.solana.config.getMintInfo("USDC").mint.toBase58(),
    []
  );
  const UXDMintAddress = useMemo(
    () => window.__UXD__.solana.config.getMintInfo("UXD").mint.toBase58(),
    []
  );

  const pythSolanaReceiver = useMemo(
    () =>
      new PythSolanaReceiver({
        connection: window.__UXD__.solana.connection,
        wallet: null as unknown as any,
      }),
    []
  );

  const findValueByPlatform = (sonarData: any, platform: string) =>
    sonarData?.elements?.find((elt: any) => elt.platformId === platform).value;

  const [positions, setPositions] = useState<{
    solana: {
      USDCVaultHolding: number | null;
      USDCHotWalletATA: number | null;
      SaberUXDUSDCPool: number | null;
      UXDVaultHolding: number | null;
      UXDHotWalletATA: number | null;
      UXDProgramAuthorityATA: number | null;
      OrcaWhirlpools: number | null;
      MapleFinanceUSDC: number | null;
      JTO: number | null;
      JUP: number | null;
    };
    ethereum: {
      USDCVaultHolding: number | null;
    };
    arbitrum: {
      USDCVaultHolding: number | null;
    };
  } | null>(null);

  useEffect(() => {
    const fetchInsuranceFundPositions = async () => {
      const mapleGov = "9uM8UiGnpbVUUo3XMiESD54PDQbdLcwdunqQMebaFu2r";

      // Launch all requests in parallel
      const ethereumUSDCDepositPromise = getEthereumUSDCDeposit();
      const arbitrumUSDCDepositPromise = getArbitrumUSDCDeposit();

      const accountsBalancesPromise = getMultipleTokenAccountBalances([
        {
          // quarryUXDUSDCSaberLPToken
          pubkey: new PublicKey("uMTKu14LJph1sv9FD27LfsA5V6KNmnnt4ZLzdw1pcxi"),
          decimals: 6,
        },
        {
          // UXDUSDCSaberUXDVault
          pubkey: new PublicKey("9zj4aX38Uxf3h6AUjS4EipWS8mwbEofasHAf3a1uKGds"),
          decimals: 6,
        },
        {
          // UXDUSDCSaberUSDCVault
          pubkey: new PublicKey("CwQDG1MWunn9cLNwcZLd8YBacweSR7ARo32w4mLua1Yr"),
          decimals: 6,
        },
        {
          // UXDProgramAuthorityATA
          pubkey: new PublicKey("REFVReVTe9cYMgxpZQ3NJ7LWRLPjPUFoQPZUcA3MezQ"),
          decimals: 6,
        },
        {
          // UXDVaultHolding
          pubkey: new PublicKey("BBUcWRZKTyByVzm9YpJsQxeWzecZbhUbr5EpW4VS8oW6"),
          decimals: 6,
        },
        {
          // UXDHotWalletATA
          pubkey: new PublicKey("GwSLedXAznA4NfvnSALGsuHG2hgope3vUYJZCXog1vu4"),
          decimals: 6,
        },
        {
          // USDCHotWalletATA
          pubkey: new PublicKey("CzztF4jyKfRQU6J4eCXhZEpMsg6iZAKtQjgQEoKrKNvZ"),
          decimals: 6,
        },
        {
          // USDCVaultHolding
          pubkey: new PublicKey("9DG795GEYQ7byWz9HAbWkw7Q7W39P3hS614VDRobiAHh"),
          decimals: 6,
        },
        {
          // Other DAO account
          pubkey: new PublicKey("FWgCYusFKJfsaN4kaiHRGfNpRTb1q7Zms8pCmPv1XCVo"),
          decimals: 6,
        },
        {
          // Other DAO account 2
          pubkey: new PublicKey("9en2QCzV4B6P9vwMkjEk11iMzW1dCrYdCpev2dXemHjq"),
          decimals: 6,
        },
        {
          // JTO
          pubkey: new PublicKey("3NWFKRPdrRwtLiMqEAyq8LLEHK27TLAgtrJupmMN7s5h"),
          decimals: 9,
        },
        {
          // JUP
          pubkey: new PublicKey("CKLRtZKLqzeNqPiNG7vuvn9K8kmh9P9s4kgM4PAu3QSM"),
          decimals: 6,
        },
      ]);

      const UXDUSDCSaberPoolLpTokenTotalSupplyPromise =
        window.__UXD__.solana.connection.getTokenSupply(
          new PublicKey("UXDgmqLd1roNYkC4TmJzok61qcM9oKs5foDADiFoCiJ")
        );

      const loadJTOandJUPPricesPromise = Promise.all([
        pythSolanaReceiver.fetchPriceUpdateAccount(
          // JTO/USD
          new PublicKey("7ajR2zA4MGMMTqRAVjghTKqPPn4kbrj3pYkAVRVwTGzP")
        ),
        pythSolanaReceiver.fetchPriceUpdateAccount(
          // JUP/USD
          new PublicKey("7dbob1psH1iZBS7qPsm3Kwbf5DzSXK8Jyg31CTgTnxH5")
        ),
      ]);

      const { orcaAmount, mapleAmount } = await (async () => {
        try {
          const [responseV1HotWallet, responseV1Maple] = await Promise.all([
            fetch(
              `${DASHBOARD_API_ENDPOINT}/api/sonar?address=${UXP_GOVERNANCE_HOT_WALLET}&system=solana`
            ),
            fetch(
              `${DASHBOARD_API_ENDPOINT}/api/sonar?address=${mapleGov}&system=solana`
            ),
          ]);

          const [dataHotWallet, dataMaple] = await Promise.all([
            responseV1HotWallet.json(),
            responseV1Maple.json(),
          ]);

          // Divide by 2 because we don't count UXP in the UXD/UXP pool
          return {
            orcaAmount: (findValueByPlatform(dataHotWallet, "orca") ?? 0) / 2,
            mapleAmount: findValueByPlatform(dataMaple, "maple"),
          };
        } catch (e) {
          console.error("Failed to load data from sonar", e);

          return {
            orcaAmount: ORCA_AMOUNT,
            mapleAmount: MAPLE_FINANCE_USDC_SOLANA,
          };
        }
      })();

      // Wait it all
      const [
        ethereumUSDCDeposit,
        arbitrumUSDCDeposit,
        [
          quarryUXDUSDCSaberLPTokenBalance,
          UXDUSDCSaberUXDVaultBalance,
          UXDUSDCSaberUSDCVaultBalance,
          UXDProgramAuthorityATABalance,
          UXDVaultHoldingBalance,
          UXDHotWalletATABalance,
          USDCHotWalletATABalance,
          USDCVaultHoldingBalance,
          OtherDaoAccountBalance,
          OtherDaoAccount2Balance,
          JTOBalance,
          JUPBalance,
        ],
        UXDUSDCSaberPoolLpTokenTotalSupply,
        [JTOPrice, JUPPrice],
      ] = await Promise.all([
        ethereumUSDCDepositPromise,
        arbitrumUSDCDepositPromise,
        accountsBalancesPromise,
        UXDUSDCSaberPoolLpTokenTotalSupplyPromise,
        loadJTOandJUPPricesPromise,
      ]);

      // Calculate saber position size
      const SaberUXDUSDCPool = (() => {
        if (
          !UXDUSDCSaberUXDVaultBalance ||
          !UXDUSDCSaberUSDCVaultBalance ||
          !UXDUSDCSaberPoolLpTokenTotalSupply?.value.uiAmount ||
          !quarryUXDUSDCSaberLPTokenBalance
        ) {
          return 0;
        }

        // Consider 1 USDC = 1 UXD = $1
        const totalValue =
          UXDUSDCSaberUXDVaultBalance + UXDUSDCSaberUSDCVaultBalance;

        // Calculate pool share
        const poolShare =
          totalValue / UXDUSDCSaberPoolLpTokenTotalSupply.value.uiAmount;

        return poolShare * quarryUXDUSDCSaberLPTokenBalance;
      })();

      const JTOValueUsd = JTOPrice
        ? Number(JTOPrice.priceMessage.price.toString()) /
          10 ** -JTOPrice.priceMessage.exponent
        : 0;

      const JUPValueUsd = JUPPrice
        ? Number(JUPPrice.priceMessage.price.toString()) /
          10 ** -JUPPrice.priceMessage.exponent
        : 0;

      setPositions({
        solana: {
          UXDVaultHolding: UXDVaultHoldingBalance ?? 0,
          UXDHotWalletATA: UXDHotWalletATABalance ?? 0,
          UXDProgramAuthorityATA: UXDProgramAuthorityATABalance ?? 0,
          USDCHotWalletATA: USDCHotWalletATABalance ?? 0,
          USDCVaultHolding:
            (USDCVaultHoldingBalance ?? 0) +
            (OtherDaoAccountBalance ?? 0) +
            (OtherDaoAccount2Balance ?? 0),
          SaberUXDUSDCPool: SaberUXDUSDCPool ?? 0,
          OrcaWhirlpools: orcaAmount,
          MapleFinanceUSDC: mapleAmount,
          JTO: JTOBalance ? JTOBalance * JTOValueUsd : 0,
          JUP: JUPBalance ? JUPBalance * JUPValueUsd : 0,
        },
        ethereum: {
          USDCVaultHolding: ethereumUSDCDeposit,
        },
        arbitrum: {
          USDCVaultHolding: arbitrumUSDCDeposit,
        },
      });
    };

    fetchInsuranceFundPositions();
  }, [USDCMintAddress, UXDMintAddress, pythSolanaReceiver]);

  return positions;
}
